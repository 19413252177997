<template>
  <div class="dgd-templates-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-avv-content">
        <div class="dgd-avv-left dg-panel-body">
          <div class="dg-document-thumbnails">
            <div class="dg-document-thumbnail" :key="index" v-for="(item, index) in block.payload">
              <p>{{ item.title }}</p>
              <i>({{ item.date }})</i>
              <a
                v-if="item.preview"
                class="dg-show dg-link-available"
                data-tippy-content="show"
                data-method="get"
                :href="'/redirect' + item.preview"
              ></a>
              <a
                v-if="item.download"
                class="dg-download dg-link-available"
                data-tippy-content="Download"
                data-method="get"
                :href="'/redirect' + item.download"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dg-ExternalAudits",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  border-top: 1px solid #dddddd;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  text-align: right;
  padding: 10px 20px;
}
.footer a {
  color: #ca3745;
  font-weight: bold;
}

.dgd-avv-content {
  display: table;
  width: 100%;
  height: 100%;
}

.dgd-avv-left,
.dgd-avv-right {
  display: table-cell;
  vertical-align: middle;
}

.dgd-avv-right {
  vertical-align: middle;
}

.dgd-avv-right a {
  display: block;
  position: relative;
  top: -20px;
  margin-right: -60px;
}

.dgd-avv-right img {
  max-height: 310px;
  max-width: 480px;
}

.dg-document-thumbnail {
  border: 1px #dcdcdc solid;
  padding: 10px;
  margin: 0 20px 20px 0;
  width: 170px;
  height: 240px;
  overflow: hidden;
  position: relative;
  background-image: url("../images/placeholder-lines.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 150px;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  transition: 0.15s ease-out;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  top: 0;
  box-shadow: none;
}

.dg-document-thumbnail:hover {
  top: -6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.dg-document-thumbnail i {
  color: #999;
  font-size: 11px;
  display: block;
}
.dg-document-thumbnail p {
  font-size: 13px;
  margin-bottom: 0;
  font-weight: bold;
  background-color: #ffffff;
}

.dg-document-thumbnail a {
  display: block;
  width: 50%;
  height: 50px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
}

.dg-document-thumbnail a.dg-show {
  left: 0;
  background-image: url("../images/dashboard-icons/download-ead.svg");
}

.dg-document-thumbnail a.dg-download {
  right: 0;
  background-image: url("../images/dashboard-icons/download-ead.svg");
}

.dg-document-thumbnail a:not(.dg-link-available) {
  opacity: 0.4;
  pointer-events: none;
}
</style>
