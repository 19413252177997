<template>
  <div class="priority-tag">
    <b :class="'tag-' + priority">{{ priority }}</b>
    <span>{{ $t(`todos.priorities.${priority}`) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    priority: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.priority-tag {
  background: #dfe4e9;
  display: flex;
  width: 100px;
  align-items: center;
  gap: 8px;

  & > b {
    display: inline-block;
    color: $white;
    background-color: $gray;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;

    &.tag-A {
      background-color: #c63040;
    }

    &.tag-B {
      background-color: #e1633b;
    }

    &.tag-C {
      background-color: #fa9334;
    }

    &.tag-D {
      background-color: #faad4b;
    }

    &.tag-E {
      background-color: #f8d06a;
    }
  }

  & > span {
    font-size: 12px;
    @include font_weight("Semibold");
    display: inline-block;
    white-space: nowrap;
  }
}
</style>
