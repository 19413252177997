<template>
  <div class="mydg-right-content">
    <!---->
    <div class="mydg-right-content-wrapper">
      <div class="dgd-it-dashboard-thumbnail">
        <img :src="getImageUrl(block.payload.it_policy_image_url)" class="img-fluid" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "it-policy",
  props: {
    block: {
      type: Object,
    },
  },
  methods: {
    getImageUrl(link) {
      return link.includes("http") ? link : `/redirect${link}`;
    },
  },
};
</script>
