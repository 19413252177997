<template>
  <div style="height: 100%">
    <component :is="engineInfo[engine].section" :block="engineData" />
  </div>
</template>
<script>
import IntroBlock from "./IntroBlock.vue";
import DSRData from "./DSRData.vue";
import CustomerRequest from "./CustomerRequest.vue";
import BAFASubsidy from "./BAFASubsidy.vue";
import ExternalAudits from "./ExternalAudits.vue";
import PrivacyDocumentation from "./PrivacyDocumentation.vue";
import Documentation from "./Documentation.vue";
import Recommendations from "./Recommendations.vue";
import PrivPolicy from "./PrivPolicy.vue";
import Academy from "./Academy.vue";
import DPOTeam from "./DPOTeam.vue";
import DPAData from "./DPAData.vue";
import DGTemplates from "./DGTemplates.vue";
import ITPolicy from "./ITPolicy.vue";
import AuditInfo from "./AuditInfo.vue";
import News from "./News.vue";
import WhistleBlowing from "./WhistleBlowing.vue";
import DGSuite from "./DGSuite.vue";
import ToDos from "./ToDos.vue";
import PPM from "./PPM.vue";
import PrivacyAudit from "./PrivacyAudit.vue";
import Infosec from "./Infosec.vue";
import KnowledgeCenter from "./KnowledgeCenter.vue";

export default {
  name: "renderEngine",
  props: {
    engine: {
      type: String,
    },
    engineData: {
      type: Object,
    },
  },
  components: {
    IntroBlock,
    AuditInfo,
    DSRData,
    CustomerRequest,
    BAFASubsidy,
    ExternalAudits,
    PrivacyDocumentation,
    Documentation,
    Recommendations,
    PrivPolicy,
    Academy,
    DPOTeam,
    DPAData,
    DGTemplates,
    ITPolicy,
    News,
    WhistleBlowing,
    DGSuite,
    ToDos,
    PPM,
    KnowledgeCenter,
    PrivacyAudit,
    Infosec,
  },
  data() {
    return {
      engineInfo: {
        welcome: {
          section: "IntroBlock",
        },
        data_subject_requests: {
          section: "DSRData",
        },
        dpa: {
          section: "DPAData",
        },
        templates: {
          section: "DGTemplates",
        },
        academy: {
          section: "Academy",
        },
        customer_request: {
          section: "CustomerRequest",
        },
        bafa_subsidy: {
          section: "BAFASubsidy",
        },
        news_section: {
          section: "News",
        },
        external_audit: {
          section: "ExternalAudits",
        },
        privacy_documentation: {
          section: "PrivacyDocumentation",
        },
        documentation: {
          section: "Documentation",
        },
        recommendations_for_action: {
          section: "Recommendations",
        },
        ppg: {
          section: "PrivPolicy",
        },
        team: {
          section: "DPOTeam",
        },
        it_policy: {
          section: "ITPolicy",
        },
        audits: {
          section: "AuditInfo",
        },
        whistleblowing: {
          section: "WhistleBlowing",
        },
        dataguard_suite: {
          section: "DGSuite",
        },
        todos: {
          section: "ToDos",
        },
        ppm: {
          section: "PPM",
        },
        privacy_audit: {
          section: "PrivacyAudit",
        },
        knowledge_center: {
          section: "KnowledgeCenter",
        },
        infosec: {
          section: "Infosec",
        },
      },
    };
  },
};
</script>
