<template>
  <dg-button class="dg-ppm__create-container__btn" @click="navigateTo">
    <img class="dg-ppm__create-container__btn-icon" :src="require(`../../images/dpa-${type}.svg`)" alt="" />
    {{ $t(`ppm.create_section.${type}.btnLabel`) }}
  </dg-button>
</template>

<script>
const routeMap = {
  check: "PPMReview",
  create: "PPMCreate",
  upload: "PPMUpload",
};
export default {
  name: "PPMCreateButton",
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["check", "create", "upload"].includes(value);
      },
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({ name: routeMap[this.type] });
    },
  },
};
</script>

<style lang="scss">
.dg-ppm__create-container__btn {
  margin-top: 8px;

  &-icon {
    margin-right: 8px;
  }
}
</style>
