<template>
  <div class="dg-bafa-countdown">
    <a class="dg-bafa" :href="bafaLink" :target="block.defaults.link.target" v-tooltip="block.defaults.tooltip">
      <div class="dg-bafa-countdown__header">
        <span class="dg-bafa-countdown__header__text"> {{ block.defaults.title }}</span>
      </div>
      <div class="dg-bafa-countdown__body">
        <span :class="checkBafaDaysLeft">
          <img class="dg-bafa__image" src="../images/stopwatch.svg" />
          {{ block.payload.countdown }}
          {{ block.payload.countdown_unit }}
        </span>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: "BAFACountdown",
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    bafaLink() {
      return `/redirect${this.block.defaults.link.href}`;
    },
    checkBafaDaysLeft() {
      return this.block.payload.countdown == 1
        ? "dg-bafa-countdown__value dg-bafa-countdown__value-red"
        : "dg-bafa-countdown__value";
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-bafa-countdown {
  position: fixed;
  z-index: 1;
  float: right;
  bottom: 80px;
  right: 30px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 3px 16px 2px rgba(0, 0, 0, 0.15);
  .dg-bafa-countdown__header {
    display: flex;
    justify-content: center;
    position: relative;
    height: 50%;
    .dg-bafa-countdown__header__text {
      position: absolute;
      top: -10px;
      width: 200px;
      text-align: center;
      padding: 24px;
      background: linear-gradient(25.7deg, #9e2b2f 34.6%, #cb333b 74.11%, #e03c31 100%);
      box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.15);
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .dg-bafa-countdown__body {
    width: 250px;
    .dg-bafa-countdown__value {
      display: flex;
      justify-content: center;
      padding: 24px;
      color: #003349;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      &-red {
        color: #cb333b;
      }
    }

    .dg-bafa__image {
      padding-right: 10px;
    }
  }
}
</style>
