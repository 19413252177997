<template>
  <div class="dgd-documentation-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-avv-content">
        <div class="dgd-avv-left dg-panel-body">
          <router-link to="dd">
            <ul class="documents" data-testId="documentation-link-block">
              <li :key="index" v-for="(item, index) in block.payload">
                <div class="document-header">
                  <img :src="item.icon_url" />
                  {{ item.title }}
                </div>
                <div class="icon">
                  <img
                    src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAtCAMAAADvGAnRAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAulBMVEX///+FmaV6kZ17kZ16kZyarLQSO1EROlEROlASOlFNa3z78vPkmqLqtLrlm6LJN0Xqs7nrtLrlmqLKOEbxzNDlm6MxVGfv8vNNanuYqbOKnamJnamJnaiJnKi0wcg/X3IiRls/XnIjR1zFztPT2t7w8/RObHzFztTS2t6ltL2XqbLi5ul5kJy2wsnEzdNOa3t5kJ0iR1xshJIiRlyInKiIm6eInKd6kJ3K09jEzdLDzdLDzNLh5ekAAAClZ2VHAAAAAWJLR0Q90G1RWQAAAAlwSFlzAAALEgAACxIB0t1+/AAAAAd0SU1FB+QHDBU6EnUKXjYAAAJLSURBVDjLzVRrd9MwDA1pkSzTrbw6wEkYLF0GG6RjlEJa/v/vQpZlJ03zhZ3DObiNX7mRr64kZ9l/0Z7keT7jJ5/P5vncL+azp9NQAEBDgEAIBpEnYGz2bBpqGBww8vDSZouzCSiRR4J2JL3NzpfPT6F8KPqDQf7Mgwe2upzAQngdcEpCoMuzUyiAsoxUFLp8cUJA3yfriAodY0F5wkADm730yFcjDhDMxg+M52Gz16vFarVYnB/pK9JHtwwgoYGLN9PRUqaGrVFgK96pcHYADeHsZcAQDyKS8QiKRhwSshSsUqDEdODtWNdeAZIeJIE8oSGUkIzyldfenqdkhP2xVYxmlS+p4cDaZn/f3rmiLMrKOVcUruTH8aIoSldUBe+WRVW68n0gyhXg/YxyYaKSJOad6L45pqq4focdVP1jopIAKAmnR4iCIQNFnqi8fAAqFmpaRihGBtwu7Qf6aK+QJxZCANRs5JpI1evr5mb96TPW6zpwjGaSW95XPpHq5nZWN3B3efPFBkWMBrtPAAqpUl9/vWo3UDfeKkmoUGKLyrVn2zYE7f393Tc2DTFb5NpJeR3FebgA8+Dcd54MZPZfJLdYIVbLH6iqphrXK8ek+yIJk16pLBTDoCWgG74UYi3oZZdYmJisBpOJYT0ghTpPum63Ff+2P3bVblfxwOuKx8q3rV/wdHf7iOxuuG2aJg3abXT6c1yxwSWTbiRN9tGVIWlKIeIY69B4dyncn3371Xb7rmu7rju0h5Yne+4OXbs/8Kzd/36ET/+g/QHl6TnLDTWCeAAAAABJRU5ErkJggg=="
                    :title="item.title"
                    :alt="item.title"
                  />
                </div>
              </li>
            </ul>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dg-documentation",
  props: {
    block: {
      type: Object,
    },
  },
  data() {
    return {
      listText: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.documents {
  display: flex;
  justify-content: center;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.documents li {
  width: 130px;
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.documents li .title {
  color: #fff;
  cursor: default;
  padding: 10px 0;
}

.documents li .document-header {
  color: $white;
  border: 6px solid $white;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  line-height: 1;
  margin-bottom: 10px;

  img {
    margin-bottom: 10px;
    height: 24px;
  }
}

.documents li:nth-child(1) .document-header {
  background: #c63040;
}

.documents li:nth-child(2) .document-header {
  background: #8897a8;
}

.documents li:nth-child(3) .document-header {
  background: #6d7b87;
}

.documents li:nth-child(4) .document-header {
  background: #434c55;
}

.documents li:nth-child(5) .document-header {
  background: #2b353f;
}

.documents li:nth-child(6) .document-header {
  background: #283038;
}

.documents li:nth-child(7) .document-header {
  background: #171c20;
}
</style>
