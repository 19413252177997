<template>
  <div class="mydg-content__wrapper dg-intro">
    <div class="mydg-left-content-wrapper">
      <h2>
        {{ block && block.payload.welcome }}
      </h2>
      <div
        class="dg-intro__pm-description"
        v-html="block.payload.customer_pm_description"
        v-if="block.payload.customer_pm_description"
        data-testId="pm-description"
      />
      <div class="dg-intro__pm-name" v-if="block.payload.customer_pm_name">
        {{ block.payload.customer_pm_name }}
      </div>
    </div>
    <template v-if="block.payload && block.payload.referral_link_panel">
      <IntroBlockLinks :block="block.payload.referral_link_panel" />
    </template>
  </div>
</template>
<script>
import IntroBlockLinks from "../components/Intro/IntroLinks.vue";

export default {
  name: "intro-block",
  components: {
    IntroBlockLinks,
  },
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-badges-block {
  position: relative;
  color: #0b344b;
  text-align: center;
  display: inline-block;
  width: 200px;

  & > .up {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 98px;
  }

  & > .down {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.5;
  }

  & > .label {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    text-align: left;
    opacity: 0.5;
    display: block;
    margin-bottom: 10px;
  }
}

.mydg-content__wrapper {
  display: flex;
}

.mydg-left-content-wrapper {
  padding-right: 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: 20px;
  }
}

.mydg-right-content-wrapper-badges {
  width: 30%;
  max-width: 320px;
}

.mydg-content-badges {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 38px 0;
}

.dg-intro {
  &__pm-description,
  &__pm-name {
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 1rem;
    text-align: justify;
  }

  &__pm-name {
    font-style: italic;
  }

  &__badge-items {
    margin-top: 40px;
  }

  .dg-intro-links {
    width: 45%;
  }
}
</style>
