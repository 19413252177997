<template>
  <div class="dgd-documentation">
    <div class="dgd-documentation__description">
      <div class="dgd-documentation__description--title">
        {{ $t("customer_dashboard.privacy_documentation.title") }}
      </div>
      <ul class="dgd-documentation__description--list">
        <li>{{ $t("customer_dashboard.privacy_documentation.audit_documentation") }}</li>
        <li>{{ $t("customer_dashboard.privacy_documentation.recommendations") }}</li>
        <li>{{ $t("customer_dashboard.privacy_documentation.protocols") }}</li>
      </ul>
    </div>
    <div class="dgd-documentation__cover">
      <img src="../images/documentation.png" />
    </div>
  </div>
</template>
<script>
export default {
  name: "dg-privacy_documentation",
  props: {
    block: {
      type: Object,
    },
  },
  data() {
    return {
      listText: [],
    };
  },
};
</script>
<style lang="scss" scoped>
.dgd-documentation {
  display: flex;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
}
.dgd-documentation .dgd-documentation__description {
  margin-right: 1.5rem;
  width: 100%;
}
.dgd-documentation__description--title {
  margin-bottom: 1.25rem;
  display: block;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}
.dgd-documentation .dgd-documentation__cover {
  width: 24rem;
  width: 384px;
}
.dgd-documentation .dgd-documentation__description--list {
  margin-left: 1.25rem;
  list-style-type: square;
}
.dgd-documentation .dgd-documentation__description--list li {
  font-weight: 700;
}
</style>
