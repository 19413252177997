<template>
  <div class="mydg-right-content-wrapper">
    <div class="dgd-avv-content">
      <div class="dgd-avv-left" v-if="shouldRenderCounter">
        <div class="dg-number-block">
          <span class="up">{{ block.payload.dpa_check_count }}</span>
          <span class="down">/{{ block.payload.dpa_limit }}</span>
          <b class="label">DPAs checked</b>
          <DgButton :href="`/redirect${block.payload.dpa_check}`">Check DPA</DgButton>
        </div>

        <div class="dg-number-block">
          <span class="up">{{ block.payload.dpa_created_count }}</span>
          <span class="down">/{{ block.payload.dpa_limit }}</span>
          <b class="label">DPAs created</b>
          <DgButton :href="`/redirect${block.payload.dpa_create}`">Create DPA</DgButton>
        </div>
      </div>
      <div class="dgd-avv-right">
        <a data-method="get" :href="'/redirect' + block.defaults.link.href">
          <img src="../images/avv-info-en.png" class="dgd-avv-info-image" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Basic } from "@/dgui-customer-components/";
export default {
  name: "dpa-data",
  components: {
    DgButton: Basic.DgButton,
  },
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    ...mapState({
      companyData: state => state.user.companyData,
    }),
    shouldRenderCounter() {
      return this.companyData.service_package !== "dpa_partner";
    },
  },
};
</script>
<style lang="scss" scoped>
.dgd-avv-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.dgd-avv-left,
.dgd-avv-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
    flex-basis: 50%;
    min-width: 50%;
  }
}

.dgd-avv-right a {
  display: block;
  position: relative;
}

.dgd-avv-right img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.dg-number-block {
  position: relative;
  color: #0b344b;
  text-align: center;
  display: inline-block;
  padding: 40px 30px;
  transition: 0.25s ease-out;
  cursor: default;
}

.dg-number-block:hover {
  background-color: #f1f1f1;
}

.dg-number-block .dg-btn {
  white-space: nowrap;
  max-width: 160px;
  padding: 10px 18px;
}

.dg-number-block > .up {
  font-size: 72px;
  line-height: 98px;
  font-weight: bold;
  letter-spacing: -4px;
}

.dg-number-block > .up-dsr {
  display: block;
  font-size: 62px;
  line-height: 83px;
  font-weight: bold;
  letter-spacing: -4px;
}

.dg-number-block > .up-dsr-red {
  display: block;
  font-size: 62px;
  line-height: 83px;
  font-weight: bold;
  color: #c63040;
}

.dg-number-block > .down {
  font-size: 42px;
  line-height: 57px;
  opacity: 0.5;
}

.dg-number-block > .down-dsr {
  font-size: 22px;
  line-height: 28.5px;
  opacity: 0.5;
}

.dg-number-block > .label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.9;
  display: block;
  margin-bottom: 10px;
}
</style>
