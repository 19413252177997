<template>
  <div>
    <div class="dgd-templates-block">
      <div class="mydg-right-content-wrapper">
        <div class="dgd-avv-content">
          <a
            class="dg-bafa"
            :href="`/redirect${block.defaults.link.href}`"
            :target="block.defaults.link.target"
            v-tooltip="block.defaults.tooltip"
          >
            <div class="dg-bafa__countdown">
              <span class="dg-bafa__countdown-value">
                {{ block.payload.countdown }}
              </span>

              <span class="dg-bafa__countdown-unit">
                {{ block.payload.countdown_unit }}
              </span>
            </div>
            <div class="dg-bafa__deadline-message">{{ block.payload.deadline_message }}</div>
          </a>
        </div>
      </div>
    </div>
    <BAFACountdown :block="block" v-if="isCountDownAvailable" />
  </div>
</template>
<script>
import BAFACountdown from "./BAFACountdown";
export default {
  name: "dg-templates",
  props: {
    block: {
      type: Object,
    },
  },
  components: {
    BAFACountdown,
  },
  computed: {
    isCountDownAvailable() {
      // disable the bafa countdown temporarily, it is too pushy
      return false && Boolean(this.block.payload.countdown && this.block.payload.countdown_unit);
    },
  },
};
</script>
<style lang="scss" scoped>
.dgd-avv-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dg-bafa {
  max-width: 300px;
  margin: 85px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  color: $white;
  position: relative;
  transition: transform 0.25s ease-out;
  background: $red center left 16px no-repeat;
  background-image: url("../images/countdown.svg");
  box-shadow: 0 6px 32px rgba(198, 48, 64, 0.3);
  padding: 14px 10px 14px 60px;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.5;
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;

  &:hover {
    transform: scale(1.05);
  }

  &__countdown {
    text-align: center;
    padding-left: 6px;
  }

  &__countdown-value {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  &__deadline-message {
    white-space: nowrap;
    padding: 0 15px 0 12px;
    text-align: center;
  }
}
</style>
