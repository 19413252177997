<template>
  <div class="dgd-team-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-team-content">
        <div class="dgd-team-left">
          <ul :class="['dgd-team-items', `dgd-team-${teamName}`]">
            <li :class="{ active: selectedMember.key === item.key }" :key="item.key" v-for="(item, index) in team">
              <div @click="selectMember(item)" class="dg-dsbPhoto" :style="`background-image:url(${item.image})`"></div>
              <div v-if="index === 0" class="star"><img src="../images/star.svg" /></div>
            </li>
          </ul>
        </div>
        <div :key="selectedMember.key" class="dgd-team-right" data-testId="dg-team-description">
          <b>{{ selectedMember.full_name }}</b>
          <small>{{ selectedMember.title }}</small>
          <span v-html="selectedMember.description"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "dpo-team",
  data() {
    return {
      selectedMember: {},
    };
  },
  props: {
    block: {
      type: Object,
      default() {
        return { payload: { main: {}, others: [] } };
      },
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    teamName() {
      let teamOtherPerson = this.team.length;
      let name = "";
      if (teamOtherPerson === 4) name = "team_with_all_member";
      if (teamOtherPerson === 3) name = "team_without_support_consultant";
      if (teamOtherPerson === 2) name = "team_without_support_consultant_and_legal_counsel";
      if (teamOtherPerson === 1) name = "team_without_support_consultant_and_legal_counsel_and_CSM";
      return name;
    },
    team() {
      let listOfMembers = [];
      const { main, others } = this.block.payload;

      if (main && main.key) {
        listOfMembers.push({
          key: main.key,
          title: main.title,
          full_name: main.full_name,
          description: main.description,
          image: main.image.url,
        });
      }

      others.forEach(item => {
        listOfMembers.push({
          key: item.key,
          title: item.title,
          full_name: item.full_name,
          description: item.description,
          image: item.image.url,
        });
      });

      return listOfMembers;
    },
  },
  created() {
    this.selectedMember = this.team[0] || {};
  },
  methods: {
    selectMember(memberData) {
      this.selectedMember = memberData;
    },
  },
};
</script>
<style lang="scss" scoped>
.dgd-team-items {
  display: block;
  width: 288px;
  height: 288px;
  text-align: center;
  margin: 20px;
  li {
    list-style: none;
    position: relative;
    background: #eee;
    float: left;
    width: 120px;
    height: 120px;
    margin: 2px;
    transition: 0.2s;
    cursor: pointer;
    &:not(.active) {
      opacity: 0.4;
    }
    .star {
      position: absolute;
      background: #fff;
      width: 24px;
      height: 24px;
      bottom: 0;
      right: 0;
    }
  }
}
.dgd-team-team_without_support_consultant_and_legal_counsel_and_CSM li:first-child,
.dgd-team-team_without_support_consultant li:first-child,
.dgd-team-team_without_support_consultant_and_legal_counsel li:first-child,
.dgd-team-team_without_support_consultant_and_legal_counsel li:nth-child(2) {
  margin-left: 61px;
}
.dg-dsbTeam > .dg-dsbTeamRow {
  display: flex;
}

.dg-dsbProfile {
  padding: 4px;
  flex: 1;
  cursor: pointer;
}

.dg-dsbPhoto {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  box-shadow: 0 0 0 transparent;
  transition: 0.25s ease-out;
}

.dg-dsbTeam.dsbBasicKit {
  .dg-dsbTeamRow {
    justify-content: space-around;
  }

  .dsbTop .dg-dsbPhoto {
    width: 150px;
    height: 150px;
  }

  .dsbBottom .dg-dsbPhoto {
    width: 115px;
    height: 115px;
  }
}

.dg-dsbTeam.dsbExpertBasic,
.dg-dsbTeam.dsbExpert {
  .dg-dsbTeamRow {
    justify-content: center;
  }

  .dsbTop .dg-dsbPhoto {
    width: 175px;
    height: 175px;
  }

  .dsbBottom .dg-dsbPhoto {
    width: 92px;
    height: 92px;
  }
}

.dgd-team-content {
  display: flex;
}

.dgd-team-right {
  text-align: left;
  margin: 20px;
  font-size: 10px;
  line-height: 16px;
  vertical-align: top;
}

.dgd-team-content .dgd-team-left,
.dgd-team-content .dgd-team-right {
  width: 50%;
}

.dgd-team-right b {
  font-size: 18px;
  display: block;
  line-height: 32px;
}

.dgd-team-right small {
  font-size: 13px;
  line-height: 30px;
  display: block;
}

.dg-dsbTeam.member-active .dg-dsbProfile .dg-dsbPhoto {
  opacity: 0.5;
}

.dg-dsbProfile:not(.active):hover .dg-dsbPhoto {
  opacity: 0.8 !important;
}

.dg-dsbTeam.member-active .dg-dsbProfile.active .dg-dsbPhoto {
  opacity: 1;
  box-shadow: 0 0 0 2px #dcdcdc, 0 6px 12px rgba(0, 0, 0, 0.2);
}
</style>
