<template>
  <div class="audit-flow">
    <div v-if="block.payload.customer_audit_areas.length">
      <div :key="key" v-for="(item, key) in auditAreas" class="audit-flow__block">
        <div class="audit-flow__progress">
          <DgAuditFlow :tooltip="auditFlow_bar_btn_tooltip(item)" :index="item.audit_area_id" :block="item || {}" />
        </div>
        <div class="audit-flow__links">
          <DgAuditFlowLinks
            :index="item.audit_area_id"
            :tooltips="getTooltip(item) || {}"
            :block="item || {}"
            @confirmSend="confirmAndSend"
          />
        </div>
      </div>
    </div>
    <div class="fix-center" v-else>
      <DgButton @click="confirmStartAudit(block.payload.links.start_audit)" nativeType="button">
        {{ block.payload.links.start_audit.label }}
      </DgButton>
    </div>
  </div>
</template>
<script>
import { Structural, Basic } from "@/dgui-customer-components/";
export default {
  methods: {
    auditFlow_bar_btn_tooltip(item) {
      let tooltip = this.getTooltip(item);
      return tooltip.bar_button;
    },
    area_not_started(item) {
      if (item.css_status !== 0) return false;
      if (item.customer_answer_present === true) return false;
      return true;
    },
    area_started(item) {
      if (item.customer_answer_present === true) return false;
      if (item.css_status !== 25) return false;
      return true;
    },
    area_filled_but_not_submitted(item) {
      if (item.customer_answer_present === true) return false;
      if (item.css_status !== 100) return false;
      return true;
    },
    area_submitted(item) {
      if (item.customer_answer_present === true) return false;
      if (item.css_status !== 5) return false;
      return true;
    },
    audit_call_acceptance_customer_necessary(item) {
      if (item.customer_answer_present === false) return false;
      if (item.audit_changes === false) return false;
      if (item.count_customer_audit_area === 0) return false;
      if (item.css_status !== 5) return false;
      return true;
    },
    audit_call_finished(item) {
      if (item.customer_answer_present === false) return false;
      if (item.css_status !== 7) return false;
      return true;
    },
    audit_area_finished(item) {
      if (!(item.css_status === 8 || item.css_status === 9)) return false;
      return true;
    },
    getTooltip(item) {
      let telephoneTooltip = this.$t("customer_dashboard.audit.tooltips.telephone_symbol");
      let tooltipCollection = {
        bar_button: "",
        start_arrow_symbol: "",
        send_symbol: "",
        telephone_symbol: "",
        check_mark_symbol: "",
      };

      if (this.area_not_started(item)) {
        // area not started
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.not_start_audit");
      } else if (this.area_started(item)) {
        // Area started
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.start_audit");
      } else if (this.area_filled_but_not_submitted(item)) {
        // Area filled but not submitted
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.filled_and_no_submit");
      } else if (this.area_submitted(item)) {
        // Area submitted
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.area_submit");
      } else if (this.audit_call_acceptance_customer_necessary(item)) {
        // Audit call acceptance by customer necessary
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.audit_call_acceptance");
      } else if (this.audit_area_finished(item)) {
        //Audit area finished
        tooltipCollection = this.$t("customer_dashboard.audit.tooltips.audit_area_finished");
      }

      if (item.audit_call) {
        const dateStr = item.audit_call.split(".")[0];
        telephoneTooltip += ` ${this.formatDate(new Date(dateStr), "dd.MM.yyyy HH:mm")}`;
      }

      return {
        ...tooltipCollection,
        telephone_symbol: telephoneTooltip,
      };
    },
    confirmStartAudit(obj) {
      this.$dgToast.show({
        type: "confirm",
        title: obj.title,
        content: obj.confirm,
        confirmButtonLabel: obj.commit,
        rejectButtonLabel: obj.cancel,
        onAccept: () => {
          this.$router.push({ name: obj.href });
        },
      });
    },
    confirmAndSend(sendAPIEndpoint) {
      // const onAccept = () => {
      //   const form = document.createElement("form");
      //   form.method = "POST";
      //   form.action = sendAPIEndpoint;
      //   document.body.appendChild(form);
      //   form.submit();
      // };
      // const params = {
      //   type: "confirm",
      //   title: this.$t("customer_dashboard.audit.sendConfirm.title"),
      //   content: this.$t("customer_dashboard.audit.sendConfirm.desc"),
      //   confirmButtonLabel: this.$t("customer_dashboard.audit.sendConfirm.confirm"),
      //   rejectButtonLabel: this.$t("customer_dashboard.audit.sendConfirm.cancel"),
      //   onAccept,
      // };
      // this.$dgToast.show(params);
      window.location.href = `/redirect${this.block.defaults.link.href}`;
    },
  },
  name: "audit-info",
  components: {
    DgAuditFlow: Structural.DgAuditFlow,
    DgAuditFlowLinks: Structural.DgAuditFlowLinks,
    DgButton: Basic.DgButton,
  },
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    auditAreas() {
      return this.block.payload.customer_audit_areas.map(auditArea => {
        return {
          ...auditArea,
          name: auditArea.name,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fix-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 200px;
}
.dgc-start-dsfa {
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 28px;
  margin-top: 20px;
  width: 100%;
  max-width: 900px;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 6px 12px rgba(#000, 0.1);
  small {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .dg-table-cell {
    padding: 20px;
    vertical-align: middle;
  }
  .dg-table-cell:first-child {
    width: 70%;
    padding-left: 30px;
  }
  .dg-table-cell:last-child {
    background-color: $lighter;
    text-align: center;
    width: 30%;
  }
}
.audit-flow {
  .audit-flow__block {
    display: flex;

    .audit-flow__progress {
      flex-grow: 1;
    }

    .audit-flow__links {
      width: 30%;
    }
  }
}
</style>
