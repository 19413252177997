<template>
  <div class="recommendations">
    <div class="recommendations__tasks">
      <a
        class="recommendations__tasks-link"
        target="_blank"
        :href="`/redirect${item.destination_url}`"
        v-for="item in block.payload.block_numbers"
        :key="item.id"
      >
        <div class="recommendations__tasks-status">
          <span class="up">{{ item.up }}</span>
          <span class="down">/{{ item.down }}</span>
        </div>
        <h4 class="recommendations__tasks-label">
          {{ item.label }}
        </h4>
      </a>
    </div>
    <ul class="recommendations__suggests-list">
      <li
        :class="`recommendations__suggests-item ${item.state}`"
        v-for="item in block.payload.block_data"
        :key="item.id"
      >
        <a class="recommendations__suggests-link" :href="`/redirect${item.link}`" target="_blank">
          <span class="recommendations__suggests-title">
            {{ item.title }}
          </span>
          <span class="recommendations__priority">
            <span :class="`letter ${item.css_class}`">{{ item.letter }} </span>
            <span class="text"> {{ item.text }} </span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "recommendations",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.recommendations {
  min-height: 195px;
  justify-content: space-between;

  &__tasks {
    width: 230px;
    max-width: 230px;
    flex-basis: 230px;
    min-width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
  }

  &__tasks-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $lighter;
      text-decoration: none;
    }
  }

  &__tasks-label {
    color: $dark;
  }

  &__tasks-status {
    font-size: 20px;
    color: #859da7;

    .up {
      color: $dark;
      font-size: 72px;
      line-height: 98px;
      font-weight: bold;
      letter-spacing: -4px;
    }

    .down {
      font-size: 42px;
      line-height: 57px;
      opacity: 0.5;
    }
  }

  &__suggests-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__suggests-item {
    width: 100%;
  }

  &__suggests-link {
    display: flex;
    align-items: center;
    padding: 9px 10px;
    font-size: 14px;
    width: 100%;
    color: $dark;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $lighter;
      text-decoration: none;
      color: $dark;
    }
  }

  &__suggests-title {
    width: 80%;
    min-width: 80%;
    max-width: 80%;
    flex-basis: 80%;
    font-size: 13px;
    word-break: break-word;

    &:before {
      content: "";
      margin-right: 16px;
      display: inline-block;
      background-color: #fff;
      width: 24px;
      height: 24px;
      border: 1px $light solid;
      border-radius: 24px;
      vertical-align: middle;
    }
  }

  &__priority {
    min-width: 100px;
    height: 26px;
    vertical-align: middle;
    display: flex;
    overflow: hidden;
    background: rgba(154, 167, 176, 0.2);
    border-radius: 26px;
    color: $dark;
    font-weight: bold;
    text-align: left;
    align-items: center;

    .text {
      font-size: 11px;
      font-weight: normal;
    }

    .letter {
      width: 32px;
      margin-right: 3px;
      line-height: 26px;
      font-weight: bold;
      color: #fff;
      font-size: 14px;
      display: inline-block;
      border-radius: 32px;
      text-align: center;
    }

    .letter.type-a {
      background: #c63040;
    }

    .letter.type-b {
      background: #e1633b;
    }

    .letter.type-c {
      background: #fa9334;
    }

    .letter.type-d {
      background: #faad4b;
    }

    .letter.type-e {
      background: #f8d16a;
    }
  }
}

@media (min-width: 768px) {
  .recommendations {
    display: flex;

    &__tasks {
      padding-top: 20px;
      margin-bottom: 0;
    }
  }
}
</style>
