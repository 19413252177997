<template>
  <div class="mydg-right-content-wrapper dg-academy">
    <a class="dg-academy__link" target="_blank" :href="block.payload.academy_url">
      <figure>
        <img src="../images/dataguard-academy.jpg" />
        <figcaption v-html="$t('customer_dashboard.academy.titleCard1')"></figcaption>
      </figure>
    </a>
    <a class="dg-academy__link" target="_blank" :href="block.payload.academy_url">
      <figure>
        <img src="../images/dataguard-academy.jpg" />
        <figcaption v-html="$t('customer_dashboard.academy.titleCard2')"></figcaption>
      </figure>
    </a>
    <a class="dg-academy__link" target="_blank" :href="block.payload.academy_url">
      <figure>
        <img src="../images/dataguard-academy.jpg" />
        <figcaption v-html="$t('customer_dashboard.academy.titleCard3')"></figcaption>
      </figure>
    </a>
  </div>
</template>

<script>
export default {
  name: "Academy",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-academy {
  display: flex;
  background-image: url(../images/academy-block-bg.png);
  background-repeat: no-repeat;
  background-position: right -50px top;
  background-size: 225px;
  padding: 45px 60px;

  &__link {
    width: 240px;
    text-align: left;
    text-indent: 0;
    border: 1px $light solid;
    margin-right: 20px;
    transition: 0.25s ease-out;
    transform: scale(1);
    box-shadow: 0 0 0 transparent;
    text-decoration: none;
    color: $dark;
    display: inline-block;
    overflow: hidden;
    backface-visibility: hidden;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }

  &__link img {
    max-width: 100%;
  }

  &__link figcaption {
    max-width: 232px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #003349;
    padding: 20px;
  }
}
</style>
