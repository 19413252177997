<template>
  <DgModalCustom :showModal="show" @closeModal="closeModal" data-testId="referral-link-modal">
    <div class="dg-referral-link">
      <h2 class="dg-referral-link__title">{{ modalInfo.texts.pop_up.title }}</h2>
      <p class="dg-referral-link__desc">{{ modalInfo.texts.pop_up.main_text }}</p>
      <div :key="'t_' + key" v-for="(text, key) in modalInfo.texts.pop_up.bullet_points">
        <span class="dg-referral-link__bullet-points">+</span> {{ text }}
      </div>
      <div class="dg-referral-link__inputbox">
        <div class="dg-referral-link__url">
          <input class="dg-referral-link__link" :value="modalInfo.invite_link" />
        </div>
        <div
          :class="[{ copied: copied }, 'dg-referral-link__copy-btn']"
          @click="showCopiedToast"
          v-clipboard="modalInfo.invite_link"
        >
          <template v-if="copied">
            <img src="../../images/referral-link-copied.svg" :alt="modalInfo.copied" />
            <span class="dg-referral-link__status_text copy-text">{{ modalInfo.copied }}</span>
          </template>
          <template v-else>
            <img src="../../images/referral-link-copy.svg" :alt="modalInfo.texts.pop_up.btn_link" />
            <span class="dg-referral-link__status_text">{{ modalInfo.texts.pop_up.btn_link }}</span>
          </template>
        </div>
      </div>
    </div>
  </DgModalCustom>
</template>

<script>
import { Structural } from "@/dgui-customer-components";

export default {
  name: "referral-link",
  components: {
    DgModalCustom: Structural.DgModalCustom,
  },
  props: {
    modalInfo: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    showCopiedToast() {
      this.copied = true;
      this.$dgToast.show({
        type: "info",
        title: this.modalInfo.texts.pop_up.copied,
        content: this.modalInfo.texts.pop_up.is_copied,
        closeTimeout: 1500,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-referral-link {
  &__title {
    color: #cb333b;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 1.2;
  }
  &__desc {
    display: block;
    font-weight: 600;
    margin-bottom: 24px;
  }
  &__bullet-points {
    color: #cb333b;
  }
  &__inputbox {
    display: flex;
    margin-top: 24px;
  }
  &__url {
    width: 100%;
    border: 1px solid #7e93a7;
    color: #cb333b;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 10px;
  }
  &__link {
    width: 100%;
    border: none;
    background: none;
    color: #cb333b;
    &:active,
    &:focus {
      outline: none;
    }
  }
  &__copy-btn {
    color: #cb333b;
    font-weight: bold;
    width: 170px;
    margin-left: 20px;
    text-align: right;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__status_text {
    margin-left: 8px;
  }
  &__copy-btn.copied {
    color: #5bc88d;
  }
}
</style>
