<template>
  <div>
    <template v-if="loading">
      <DGShimComponent />
    </template>
    <template v-else>
      <div class="dg-suite">
        <div class="dg-suite-elements">
          <router-link
            :to="{ name: 'DGSuiteProductDetails', params: { product_id: product.id } }"
            class="content__box content__box__link"
            v-for="(product, i) in products"
            :key="i"
          >
            <div
              class="content__box__image"
              :style="{ backgroundImage: 'url(' + product.images.find(element => element.type === 'main').url + ')' }"
            ></div>
            <div class="content__box__message">
              <h2>{{ product.category_name }}</h2>
              <h1>{{ product.short_name }}</h1>
              <p>{{ product.teaser }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DGShimComponent from "../../DGSuite/components/DGShimComponent";

export default {
  name: "dg-suite",
  components: {
    DGShimComponent,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    try {
      await this.fetchHighlightProducts();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(["fetchHighlightProducts"]),
  },
  computed: {
    ...mapState({
      products: state => state.dg_suite.products,
    }),
  },
};
</script>

<style lang="scss" scoped>
.dg-suite-elements {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.content__box {
  margin: 10px;
  background: #ffffff;
  border: 1px solid #c9d2d7;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.15);
  width: 248px;
  height: 295px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__link {
    text-decoration: none;
    color: #003349;
  }
}

.content__box__image {
  background-size: cover;
  height: 142px;
  width: 248px;
}

.content__box__message {
  padding: 0 10px;

  h1 {
    font-size: 18px;
    line-height: 24px;
  }

  h2 {
    padding-top: 11px;
    color: #7e93a7;
    font-size: 12px;
    line-height: 16px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #254a5d;
    margin-bottom: 8px;
  }
}
</style>
