import { render, staticRenderFns } from "./WhistleBlowing.vue?vue&type=template&id=e40a1cfa&scoped=true&"
var script = {}
import style0 from "./WhistleBlowing.vue?vue&type=style&index=0&id=e40a1cfa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e40a1cfa",
  null
  
)

export default component.exports