<template>
  <router-link style="text-decoration: none; color: inherit" :to="{ name: 'ToDos' }">
    <div class="todos">
      <template v-if="loading"></template>
      <template v-if="openTodos > 0">
        <div class="todos__tasks">
          <div class="todos__tasks-link">
            <div class="todos__tasks-status">
              <span class="done">{{ doneTodos }}</span>
              <span class="all">/{{ todos.length }}</span>
            </div>
            <h4 class="todos__tasks-label">
              {{ $t("todos.dashboard_box.todos_completed") }}
            </h4>
          </div>
        </div>
        <ul class="todos__suggests-list">
          <li v-for="todo in todos.slice(0, 5)" :key="todo.id" :class="`todos__suggests-item ${todo.state}`">
            <div class="todos__suggests-link">
              <span class="todos__suggests-title">
                {{ todo.title.substr(0, 50) + "..." }}
              </span>
              <Priority v-if="todo.priority" :priority="todo.priority" />
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="todos__empty-state">
          <div class="todos__empty-state-text">
            <h1>{{ $t("todos.dashboard_box.empty_state") }}</h1>
          </div>
          <div class="todos__empty-state-checkmark">
            <img src="../images/todos_shield.svg" alt="" />
          </div>
        </div>
      </template>
    </div>
  </router-link>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Priority from "../../ToDo/components/Priority";

export default {
  components: {
    Priority,
  },
  methods: {
    ...mapActions(["fetchTodos"]),
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState({
      todos: state => Object.values(state.todos.todos),
    }),
    openTodos() {
      return this.todos.filter(task => task.state == "open").length;
    },
    doneTodos() {
      return this.todos.filter(task => task.state == "done").length;
    },
  },
  async created() {
    try {
      await this.fetchTodos();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.todos {
  min-height: 195px;
  justify-content: space-between;

  &__empty-state {
    padding: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__empty-state-text {
    flex-grow: 2;
    text-align: center;
  }

  &__tasks {
    width: 230px;
    max-width: 230px;
    flex-basis: 230px;
    min-width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 30px;
  }

  &__tasks-link {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $lighter;
      text-decoration: none;
    }
  }

  &__tasks-label {
    color: $dark;
  }

  &__tasks-status {
    font-size: 20px;
    color: #859da7;

    .done {
      color: $dark;
      font-size: 72px;
      line-height: 98px;
      font-weight: bold;
      letter-spacing: -4px;
    }

    .all {
      font-size: 42px;
      line-height: 57px;
      opacity: 0.5;
    }
  }

  &__suggests-list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__suggests-item {
    width: 100%;
  }

  &__suggests-link {
    display: flex;
    align-items: center;
    padding: 9px 10px;
    font-size: 14px;
    width: 100%;
    color: $dark;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $lighter;
      text-decoration: none;
      color: $dark;
    }
  }

  &__suggests-title {
    width: 80%;
    min-width: 80%;
    max-width: 80%;
    flex-basis: 80%;
    font-size: 13px;
    word-break: break-word;

    &:before {
      content: "";
      margin-right: 16px;
      display: inline-block;
      background-color: #fff;
      width: 24px;
      height: 24px;
      border: 1px $light solid;
      border-radius: 24px;
      vertical-align: middle;
    }
  }
}

@media (min-width: 768px) {
  .todos {
    display: flex;

    &__tasks {
      padding-top: 20px;
      margin-bottom: 0;
    }
  }
}
</style>
