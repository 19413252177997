<template>
  <div class="dg-intro-links">
    <ul class="dg-intro-links__list">
      <li
        class="dg-intro-links__item"
        v-for="item in itemList"
        :key="item.key"
        @click="action(item.key)"
        :data-testId="item.testId"
      >
        <div class="dg-intro-links__text">
          <h1>{{ item.title }}</h1>
          <p>{{ item.text }}</p>
        </div>
        <div class="dg-intro-links__icon">
          <img src="../../images/arrow-right.svg" />
        </div>
      </li>
    </ul>
    <ReferralModal :show="showReferralLinkModal" :modalInfo="block" @close="closeReferralLinkModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ReferralModal from "./ReferralModal.vue";

export default {
  components: {
    ReferralModal,
  },
  props: {
    block: {
      type: Object,
    },
  },
  data() {
    return {
      showReferralLinkModal: false,
    };
  },
  computed: {
    itemList() {
      let list = [];
      /**
       * Hide View Profile for Users with only academy_access
       */
      if (!this.companyData.academy_access) {
        list.push({
          title: this.$t("customer_dashboard.welcome.view_profile"),
          text: this.$t("customer_dashboard.welcome.manage_your_personal_information_account"),
          key: "view_profile",
          testId: "intro-block-view-profile",
        });
      }
      /**
       * Show Basic Information link only for Users who are main contact in current Customer
       */
      if (this.companyData.is_current_user_main_contact) {
        list.push({
          title: this.$t("customer_dashboard.welcome.view_basic_information"),
          text: this.$t("customer_dashboard.welcome.manage_your_company_information"),
          key: "view_basic_information",
          testId: "intro-block-view-basic-information",
        });
      }
      list.push({
        title: this.$t("customer_dashboard.welcome.become_privacy_hero"),
        text: this.$t("customer_dashboard.welcome.if_you_are_happy_with_dataGuard"),
        key: "become_privacy_hero",
        testId: "intro-block-become-privacy-hero",
      });
      return list;
    },
    ...mapGetters(["companyData"]),
  },
  methods: {
    async action(key) {
      const { lang: locale, id } = this.$route.params;

      if (key === "view_profile") this.$router.push({ path: `/${locale}/c/${id}/profile` });
      if (key === "view_basic_information") {
        window.location.href = "/redirect?app=blue&redirect=false";
      }
      if (key === "become_privacy_hero") this.showReferralLinkModal = true;
    },
    closeReferralLinkModal() {
      this.showReferralLinkModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-intro-links {
  background-color: $white;
  position: relative;
  overflow: hidden;
  padding: 32px;
  padding-right: 0;
  margin-left: 16px;
  min-height: 420px;

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    list-style: none;
    cursor: pointer;
    background: #fff;
    display: flex;
    padding: 10px 10px 10px 16px;
    margin-bottom: 10px;
  }

  &__text {
    width: calc(100% - 40px);
    margin-right: 10px;
    h1 {
      color: #003349;
      font-size: 16px;
    }
    p {
      color: #7e93a7;
      font-size: 12px;
    }
  }

  &__icon {
    position: relative;
    width: 40px;
    img {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
</style>
