<template>
  <div class="ppm-dashboard-section">
    <div class="ppm-dashboard-section__table">
      <p class="ppm-dashboard-section__subtitle">{{ $t("customer_dashboard.ppm.table_title") }}</p>
      <DgSpinner v-if="fetchingPPMContracts" />
      <template v-else>
        <dg-table
          v-if="Boolean(ppmRowData.length)"
          :columns="ppmColumnData"
          :rows="ppmRowData"
          :enableRowClick="true"
          @onRowClick="onPPMRowClick"
        />
        <h3 class="ppm-dashboard-section__no-data" v-else>
          {{ $t("ppm.list_section.no_data_found") }}
        </h3>
      </template>
    </div>
    <div class="ppm-dashboard-section__btns">
      <p class="ppm-dashboard-section__subtitle">{{ $t("customer_dashboard.ppm.create_btn") }}</p>
      <div class="ppm-dashboard-section__btns-container">
        <PPMCreateButton type="check" v-can.add="'portal_for_dpas_and_jcas_review_workflow'" />
        <PPMCreateButton type="create" v-can.add="'portal_for_dpas_and_jcas_creation_workflow'" />
        <PPMCreateButton type="upload" v-can.add="'portal_for_dpas_and_jcas_upload_workflow'" />
      </div>
    </div>
  </div>
</template>

<script>
import PPMCreateButton from "@/engines/PPM/components/PPMDashboard/PPMCreateButton.vue";
export default {
  name: "PPMDashboardSection",
  components: {
    PPMCreateButton,
  },
  data() {
    return {
      fetchingPPMContracts: true,
      ppmColumnData: [
        { title: this.$t("ppm.list_section.column_labels.vendor"), field: "vendor" },
        { title: this.$t("ppm.list_section.column_labels.type"), field: "contract_type" },
        { title: this.$t("ppm.list_section.column_labels.role"), field: "participant_role" },
        { title: this.$t("ppm.list_section.column_labels.status"), field: "status" },
      ],
      ppmRowData: [],
    };
  },
  created() {
    this.fetchLatestPPMContracts();
  },
  methods: {
    async fetchLatestPPMContracts() {
      const cid = this.$route.params.id;
      const queryString = "only_parents=true&page=1&per=3&search=&sorted[column]=created_at&sorted[direction]=desc";
      const { data: ppmPayload } = await this.$http.get(`/api1/v2/customers/${cid}/privacy_contracts?${queryString}`);
      this.fetchingPPMContracts = false;
      if (ppmPayload?.data) {
        this.ppmRowData = ppmPayload.data.map(dpaContract => {
          const { id, partner_name: vendor, contract_type, participant_role, status } = dpaContract;
          return {
            id,
            vendor,
            contract_type: this.$t(`ppm.list_section.data_labels.type.${contract_type}`),
            participant_role: this.$t(`ppm.list_section.data_labels.role.${participant_role}`),
            status: this.$t(`ppm.list_section.data_labels.status.${status}`),
          };
        });
      }
    },
    onPPMRowClick(ppmDetails) {
      this.$router.push({ name: "PPMDetails", params: { ppmId: ppmDetails.id } });
    },
  },
};
</script>

<style lang="scss">
.ppm-dashboard-section {
  display: flex;

  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .dg-spinner,
  &__no-data {
    text-align: center;
    margin-top: 48px;
  }

  .dg-spinner > .dg-spinner__icon {
    width: 42px;
    height: 42px;
  }

  &__table {
    flex: 1;
  }

  &__btns {
    min-width: 220px;
    margin-left: 24px;

    &-container > * {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
