<template>
  <div>
    <div class="mydg-right-content">
      <div class="mydg-right-numbers-wrapper">
        <div class="dg-number-block">
          <span class="up-dsr-red">{{ block.payload.open_request_count }}</span>
          <span class="down-dsr">{{ $t("customer_dashboard.data_subject_requests.requests") }}</span>
          <b class="label">{{ $t("customer_dashboard.data_subject_requests.open_lowercase") }}</b>
        </div>
      </div>
      <div class="mydg-right-numbers-wrapper">
        <div class="dg-number-block">
          <span class="up-dsr">{{ block.payload.average_closing_days }}</span>
          <span class="down-dsr">{{ $t("customer_dashboard.data_subject_requests.days") }}</span>
          <b class="label">{{ $t("customer_dashboard.data_subject_requests.average_processing_time") }}</b>
        </div>
      </div>
      <div
        class="mydg-right-numbers-wrapper dg-no-requests-wrapper"
        v-if="block.payload.open_request_count === 0 && block.payload.total_request_count === 0"
      >
        <div class="dg-number-block dg-no-requests">
          <img src="../images/thumbs_up.svg" />
          <div>
            <div class="dg-no-requests_text">
              <strong>{{ $t("customer_dashboard.data_subject_requests.no_open_dsr1") }}</strong>
            </div>
            <div class="dg-no-requests_text">{{ $t("customer_dashboard.data_subject_requests.no_open_dsr2") }}</div>
          </div>
        </div>
      </div>
      <div class="mydg-right-content-wrapper"></div>
    </div>
    <div class="mydg-app-link">
      <a id="use-vue2" :href="block.defaults.href">{{ block.defaults.link.label }}</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "dsr-data",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.mydg-right-content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.dg-number-block {
  position: relative;
  color: #0b344b;
  text-align: center;
  display: inline-block;
  padding: 40px;
  transition: 0.25s ease-out;
}

.dg-number-block:hover {
  background-color: #f1f1f1;
}

.dg-number-block .dg-btn {
  white-space: nowrap;
  max-width: 160px;
  padding: 10px 18px;
}

.dg-number-block > .up {
  font-size: 72px;
  line-height: 98px;
  font-weight: bold;
  letter-spacing: -4px;
}

.dg-number-block > .up-dsr {
  display: block;
  font-size: 62px;
  line-height: 83px;
  font-weight: bold;
  letter-spacing: -4px;
}

.dg-number-block > .up-dsr-red {
  display: block;
  font-size: 62px;
  line-height: 83px;
  font-weight: bold;
  color: #c63040;
}

.dg-number-block > .down {
  font-size: 42px;
  line-height: 57px;
  opacity: 0.5;
}

.dg-number-block > .down-dsr {
  font-size: 22px;
  line-height: 28.5px;
  opacity: 0.5;
}

.dg-number-block > .label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.9;
  display: block;
  margin-bottom: 10px;
}

.mydg-app-link {
  position: absolute;
  bottom: -71px;
  right: 0;
  width: 100%;
  background: #fff;
  text-align: right;
  border-top: 1px #dcdcdc solid;
}

.mydg-app-link a {
  display: inline-block;
  color: #c63040;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  padding: 6px 20px;
}

.dg-no-requests {
  display: flex;
  width: 400px;
  height: 150px;
  align-items: center;
  background: #f1f1f1;
  border-radius: 75px;
  text-align: left;
  margin-left: auto;
}

.dg-no-requests .dg-no-requests_text {
  margin-left: 33px;
}

.mydg-right-content .mydg-right-numbers-wrapper {
  text-align: center;
  max-width: 320px;
  vertical-align: middle;
}
</style>
