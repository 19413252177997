<template>
  <div class="dgd-templates-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-avv-content">
        <div v-if="isLoading" class="shimmer-container">
          <div :key="'loading_' + n" v-for="n in 3" class="shimmer-wrapper">
            <div class="shine box"></div>
            <div class="shim-container">
              <div class="shine lines"></div>
              <div class="shine lines"></div>
              <div class="shine lines"></div>
            </div>
          </div>
        </div>
        <div class="box-slider" v-else-if="Boolean(dataBlocks.length)">
          <div :style="{ width: blockfullSize + 'px', left: sliderPosition + 'px' }" class="sliderItem">
            <div v-for="(row, index) in dataBlocks" :key="row.id" class="news-post">
              <div class="cover">
                <img :key="`img_${row.id}`" :src="row.featuredImage" :alt="row.featuredImageAltText" />
              </div>
              <div class="text-post">
                <p class="date">{{ getFormattedDate(row.publishDate) }}</p>
                <h3>
                  <a :href="row.url" target="_blank" :data-testId="`dg-news-teaser-${index}`"> {{ row.name }} </a>
                </h3>
                <div class="short-text">
                  <p>{{ stripHtml(row.postBody) }}</p>
                </div>
              </div>
            </div>
            <div class="news-post" v-if="fetchingNextPage">
              <div class="shimmer-wrapper">
                <div class="shine box"></div>
                <div class="shim-container">
                  <div class="shine lines"></div>
                  <div class="shine lines"></div>
                  <div class="shine lines"></div>
                </div>
              </div>
            </div>
          </div>
          <span class="shade-after" />
          <button
            v-on:click="controlSlider('next')"
            class="slider-btn btn-right"
            v-bind:class="{ active: Math.abs(sliderPosition) <= (dataBlocks.length - 2) * this.blockSize }"
          >
            <img src="../images/dashboard-icons/btn-right.svg" />
          </button>
          <button
            v-bind:class="{ active: sliderPosition != 0 }"
            v-on:click="controlSlider('previous')"
            class="slider-btn btn-left"
          >
            <img src="../images/dashboard-icons/btn-left.svg" />
          </button>
        </div>
        <template v-else>
          <div class="no-data-found">
            <h2>{{ $t("customer_dashboard.news_portal.no_data.title") }}</h2>
            <p>{{ $t("customer_dashboard.news_portal.no_data.subtitle") }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "dg-news",
  props: {
    block: {
      type: Object,
    },
  },
  data() {
    return {
      blockSize: 320,
      sliderPosition: 0,
      isLoading: true,
      fetchingNextPage: false,
      dataBlocks: [],
      nextPage: "",
    };
  },
  computed: {
    isShowingLastTwoSlides() {
      return this.blockSize * (this.dataBlocks.length - 2) <= Math.abs(this.sliderPosition);
    },
    blockfullSize() {
      return parseInt(this.dataBlocks.length) * this.blockSize;
    },
    ...mapGetters(["hubspotDetails"]),
  },
  methods: {
    controlSlider(state) {
      if (state == "next") {
        this.sliderPosition -= this.blockSize;
      } else if (state == "previous" && this.sliderPosition < 0) {
        this.sliderPosition += this.blockSize;
      }
      if (this.nextPage && this.isShowingLastTwoSlides && !this.fetchingNextPage) {
        // fetch next page
        this.fetchDashboardData();
      }
    },
    async fetchDashboardData() {
      const { magazineTopicId } = this.hubspotDetails;
      let queryParams = `content_group_id=${magazineTopicId}`;
      if (this.nextPage) {
        this.fetchingNextPage = true;
        queryParams += `&after=${this.nextPage}`;
      }
      try {
        const {
          data: { results = [], paging = {} },
        } = await this.$http.get(`/news-posts?${queryParams}`);
        if (results.length > 0) {
          const publishedArticles = results.filter(this.filterPublishedNews);
          this.dataBlocks = [...this.dataBlocks, ...publishedArticles];
        }
        this.nextPage = paging?.next?.after || "";
      } catch (err) {
        this.apiCatch(err);
      } finally {
        this.isLoading = false;
        this.fetchingNextPage = false;
      }
    },
  },
  mounted() {
    this.fetchDashboardData();
  },
};
</script>
<style lang="scss" scoped>
.dgd-avv-content {
  display: table;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.btn-right {
  right: 6px;
  top: 140px;
}
.btn-left {
  left: 9px;
  top: 140px;
}
.slider-btn {
  opacity: 0.5;
  width: 48px;
  position: absolute;
  height: 48px;
  background: #254a5d;
  text-align: center;
  border: none;
  border-radius: 100px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s;
  &:not(.active) {
    display: none;
  }
  &:hover .btn-right,
  &:hover .btn-left {
    opacity: 1;
  }
  &:hover,
  &:active {
    outline: none;
  }
}
.sliderItem {
  position: relative;
  transition: all 0.4s;
  min-height: 400px;
  display: flex;
}
.box-slider {
  overflow: hidden;
  position: relative;
  max-width: 730px;
  min-height: 400px;
}
@media screen and (max-device-width: 800px) {
  .box-slider {
    max-width: 330px;
  }
}
.shade-after {
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 54%);
  height: 100%;
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 100px;
}
.text-post {
  margin: 10px;
}

.news-post {
  position: static;
  float: left;
  height: 380px;
  width: 300px;
  overflow: hidden;
  margin: 6px 15px 8px 5px;
  left: 40px;
  top: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  flex: none;
  order: 0;
  align-self: center;
  h3,
  p {
    margin: 0;
    padding: 0;
  }
  h3 {
    display: block;
    margin-bottom: 10px;
  }
  h3 a {
    text-decoration: none;
    color: #003349;
  }
  .cover {
    height: 127px;
    background: #ddd;
    overflow: hidden;
  }
  .cover img {
    width: 100%;
  }
  p.date {
    color: #cb333b;
    font-weight: bold;
    font-size: 11px;
  }
}

.no-data-found {
  text-align: center;
}

.short-text {
  margin-bottom: 20px;
  font-size: 11px;
  line-height: 2;
}

.shimmer-container {
  display: flex;
}

.shimmer-wrapper {
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  width: 300px;
  display: flex;
  flex-direction: column;

  .shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 300px;
    position: relative;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  .box {
    flex-grow: 1;
    height: 150px;
  }

  .shim-container {
    display: inline-flex;
    flex-direction: column;
    margin-top: 15px;
    vertical-align: top;
  }

  .lines {
    flex-grow: 1;
    height: 10px;
    margin-top: 10px;
  }

  .photo {
    display: block !important;
    width: 325px;
    height: 100px;
    margin-top: 15px;
  }
}

.unread {
  position: relative;

  > td {
    font-weight: bolder;
    position: relative;
    background: rgba(254, 242, 192, 0.4);
  }
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
