<template>
  <div class="row">
    <div class="col-4">
      <div class="dg-module-card">
        <div class="dg-module-card__image">
          <img src="../images/privacy_audit/todo.svg" />
        </div>
        <h1 class="dg-module-card__title">{{ $t("customer_dashboard.privacy_audit.fill_and_send.title") }}</h1>
        <ul class="dg-module-card__list">
          <li>{{ $t("customer_dashboard.privacy_audit.fill_and_send.items.select_processes") }}</li>
          <li>{{ $t("customer_dashboard.privacy_audit.fill_and_send.items.answer_questions") }}</li>
        </ul>
      </div>
    </div>
    <div class="col-4">
      <div class="dg-module-card">
        <div class="dg-module-card__image">
          <img src="../images/privacy_audit/calls.svg" />
        </div>
        <h1 class="dg-module-card__title">{{ $t("customer_dashboard.privacy_audit.calls.title") }}</h1>
        <ul class="dg-module-card__list">
          <li>{{ $t("customer_dashboard.privacy_audit.calls.items.clarifying_open_question") }}</li>
          <li>{{ $t("customer_dashboard.privacy_audit.calls.items.end_the_analysis_phase") }}</li>
        </ul>
      </div>
    </div>
    <div class="col-4">
      <div class="dg-module-card">
        <div class="dg-module-card__image">
          <img src="../images/privacy_audit/documentation.svg" />
        </div>
        <h1 class="dg-module-card__title">{{ $t("customer_dashboard.privacy_audit.documentation.title") }}</h1>
        <ul class="dg-module-card__list">
          <li>{{ $t("customer_dashboard.privacy_audit.documentation.items.audit_records") }}</li>
          <li>{{ $t("customer_dashboard.privacy_audit.documentation.items.record_of_processing_activities") }}</li>
          <li>{{ $t("customer_dashboard.privacy_audit.documentation.items.recommendations") }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyAudit",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-module-card {
  &__image {
    background-color: #f2f4f6;
    text-align: center;
    padding: 23px 0;
  }

  &__title,
  &__list {
    margin: 0;
    padding: 0;
  }

  &__title {
    margin-top: 6px;
  }

  &__list {
    list-style-type: none;
    margin-top: 14px;
    li {
      &:before {
        vertical-align: middle;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 10px;
        background: #36b2e8;
        content: "";
      }
    }
  }
}
</style>
