<template>
  <div class="dgd-templates-block">
    <div class="mydg-right-content-wrapper">
      <div class="dgd-avv-content">
        <div class="dgd-avv-left dg-panel-body">
          <div class="dg-document-thumbnails">
            <div :key="index" v-for="(document, index) in block.payload" class="dg-document-thumbnail">
              <p>{{ document.title }}</p>
              <a
                :key="i"
                v-for="(lang, i) in document.links"
                :class="['dg-document-flag', `dg-flag-${i}`, 'dg-flag-available']"
                data-tippy-content="Download DE PDF"
                data-method="get"
                :href="`/redirect/${lang}`"
              ></a>
              <!-- <a
                class="dg-document-flag dg-flag-en dg-flag-available"
                data-tippy-content="Download EN PDF"
                data-method="get"
                href="/en/t/cs/155/ts/29?language=english"
              ></a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "dg-templates",
  props: {
    block: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.dgd-avv-content {
  display: table;
  width: 100%;
  height: 100%;
}

.dgd-avv-left,
.dgd-avv-right {
  display: table-cell;
  vertical-align: middle;
}

.dgd-avv-right {
  vertical-align: middle;
}

.dgd-avv-right a {
  display: block;
  position: relative;
  top: -20px;
  margin-right: -60px;
}

.dgd-avv-right img {
  max-height: 310px;
  max-width: 480px;
}

.dg-document-thumbnail {
  border: 1px #dcdcdc solid;
  padding: 10px;
  margin: 0 20px 20px 0;
  width: 170px;
  height: 240px;
  overflow: hidden;
  position: relative;
  background-image: url("../images/placeholder-lines.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 150px;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
  transition: 0.15s ease-out;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  top: 0;
  box-shadow: none;
}

.dg-document-thumbnail:hover {
  top: -6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dg-document-thumbnail p {
  font-size: 13px;
  font-weight: bold;
  background-color: #ffffff;
}

.dg-document-thumbnail a {
  display: block;
  width: 50%;
  height: 50px;
  bottom: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
}

.dg-document-thumbnail a.dg-flag-de {
  left: 0;
  background-image: url("../images/german-flag.svg");
}

.dg-document-thumbnail a.dg-flag-de-right {
  right: 0;
  background-image: url("/assets/download-de-21345a57f202835ace4dbe3c0409256636b85ef1c6a5be515dac523c710e0f4c.svg");
}

.dg-document-thumbnail a.dg-flag-en {
  right: 0;
  background-image: url("../images/download-en.svg");
}

.dg-document-thumbnail a:not(.dg-flag-available) {
  opacity: 0.4;
  pointer-events: none;
}

.dg-document-thumbnail a.dg-download-document {
  right: 0;
  background-image: url("/assets/dashboard-icons/download-ead-293c68c2e87f650e4b5d5e036ecab0c6689fd193ac1eab12634e3840e024edb3.svg");
}

.dg-document-thumbnail a.dg-download-document.left {
  right: auto;
  left: 0;
}

.dg-document-thumbnail a.dg-preview-document {
  left: 0;
  background-image: url("/assets/dashboard-icons/preview-ead-8fe8bbfcfba577162711970b13ca43cb2e75627cd8b34dc414f985e365e824ee.svg");
}
</style>
