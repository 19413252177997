<template>
  <div class="mydg-right-content-wrapper">
    <div class="dgd-icon-blocks-grey">
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-100"
        data-method="get"
        v-if="block.payload.items.includes('website')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.website") }}
      </a>
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-200"
        data-method="get"
        v-if="block.payload.items.includes('facebook-fansite')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.facebook_fanpage") }}
      </a>
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-300"
        data-method="get"
        v-if="block.payload.items.includes('online-shop')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.online_shop") }}
      </a>
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-600"
        data-method="get"
        v-if="block.payload.items.includes('board')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.board") }}
      </a>
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-400"
        data-method="get"
        v-if="block.payload.items.includes('app')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.app") }}
      </a>
      <a
        target="_blank"
        class="dgd-icon-button dgd-type-500"
        data-method="get"
        v-if="block.payload.items.includes('platform')"
        :href="ppgLink"
      >
        {{ $t("customer_dashboard.ppg.platform") }}
      </a>
    </div>
    <div class="dgd-ppg-offer-box">
      <div class="mydg-csh-icon"></div>
      <div>
        <p>
          {{ block.payload.basic_package_description }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "priv-policy",
  props: {
    block: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    actualPPGLink() {
      const { defaults = {} } = this.block;
      return defaults && defaults.link && defaults.link.href;
    },
    ppgLink() {
      const { lang = "de", id } = this.$route.params;
      return this.actualPPGLink;
    },
  },
};
</script>
<style lang="scss" scoped>
.dgd-icon-blocks-grey {
  padding-top: 25px;
}

.dgd-ppg-offer-box {
  display: none;
}

.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-type-300,
.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-type-400,
.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-type-500,
.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-type-600 {
  opacity: 0.5;
  pointer-events: none;
}

.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-ppg-offer-box {
  display: table;
  position: absolute;
  left: 335px;
  top: 65px;
  width: 530px;
  background-color: $white;
  padding-right: 0;
  height: 180px;
  border: 1px solid #f1f1f1;
}

.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-ppg-offer-box .mydg-csh-icon {
  width: 180px;
  background: #f1f1f1;
  // background-image: url("../images/customer-handshake.svg");
  background-repeat: no-repeat;
  background-position: center center;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
}

.dgd-icon-blocks-grey.dgd-ppg-offer .dgd-ppg-offer-box p {
  padding: 25px;
  margin: 0;
}

.dgd-icon-blocks-grey .dgd-icon-button {
  background-color: $white;
  box-shadow: 0px 6px 12px rgba(17, 42, 56, 0.1);
  width: 110px;
  height: 160px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 20px;
  padding-top: 106px;
  font-size: 14px;
  color: #0b344b;
  margin: 20px 20px 20px 0;
  position: relative;
  text-decoration: none !important;
  transition: 0.25s ease-out;
  transform: scale(1);
}

.dgd-icon-blocks-grey .dgd-icon-button:hover {
  transform: scale(1.1);
}

.dgd-icon-blocks-grey .dgd-icon-button:active {
  transform: scale(0.95);
}

.dgd-icon-blocks-grey .dgd-icon-button:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  top: 28px;
  left: 50%;
  margin-left: -30px;
  background-color: #0b344b;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 22px;
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-100:before {
  background-color: #7d8c9e;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_100.svg");
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-200:before {
  background-color: #6d7b87;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_200.svg");
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-300:before {
  background-color: #4c5660;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_300.svg");
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-400:before {
  background-color: #2b353f;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_400.svg");
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-500:before {
  background-color: #16232f;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_500.svg");
}

.dgd-icon-blocks-grey .dgd-icon-button.dgd-type-600:before {
  background-color: #0b344b;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_600.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button {
  background-color: $white;
  box-shadow: 0px 6px 12px rgba(17, 42, 56, 0.1);
  width: 130px;
  height: 130px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 240px;
  font-size: 14px;
  color: #0b344b;
  margin: 20px 10px;
  margin-top: 80px;
  position: relative;
  text-decoration: none !important;
}

.dgd-icon-blocks-grey .dgd-icon-import-button:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  top: 28px;
  left: 50%;
  margin-left: -30px;
  background-color: #0b344b;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 22px;
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-100:before {
  background-color: #7d8c9e;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_100.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-200:before {
  background-color: #6d7b87;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_200.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-300:before {
  background-color: #4c5660;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_300.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-400:before {
  background-color: #2b353f;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_400.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-500:before {
  background-color: #16232f;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_500.svg");
}

.dgd-icon-blocks-grey .dgd-icon-import-button.dgd-type-600:before {
  background-color: #0b344b;
  background-image: url("../images/dashboard-icons/ppg-icons/icon_600.svg");
}
</style>
