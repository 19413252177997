<template>
  <div class="cxr-wrapper">
    <div class="cxr-wrapper__container">
      <div v-if="postError" class="cxr-wrapper__main">
        <div class="cxr-wrapper__error-container">
          <svg viewBox="0 0 455.111 455.111" style="enable-background: new 0 0 455.111 455.111" width="75">
            <circle style="fill: #c63040" cx="227.556" cy="227.556" r="227.556" />
            <path
              style="fill: #ffffff"
              d="M331.378,331.378c-8.533,8.533-22.756,8.533-31.289,0l-72.533-72.533l-72.533,72.533
              c-8.533,8.533-22.756,8.533-31.289,0c-8.533-8.533-8.533-22.756,0-31.289l72.533-72.533l-72.533-72.533
              c-8.533-8.533-8.533-22.756,0-31.289c8.533-8.533,22.756-8.533,31.289,0l72.533,72.533l72.533-72.533
              c8.533-8.533,22.756-8.533,31.289,0c8.533,8.533,8.533,22.756,0,31.289l-72.533,72.533l72.533,72.533
              C339.911,308.622,339.911,322.844,331.378,331.378z"
            />
          </svg>
          <div class="cxr-wrapper__error-content">
            <div class="cxr-wrapper__error-title">{{ block.payload.fail_error.title }}</div>
            <div class="cxr-wrapper__error-desc">{{ block.payload.fail_error.desc }}</div>
          </div>
        </div>
      </div>
      <div v-else-if="success" class="cxr-wrapper__main">
        <div class="cxr-wrapper__success-container" data-testId="dg-cd-cr-success-msg">
          <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M38 76C17.0461 76 0 58.9531 0 38C0 17.0469 17.0461 0 38 0C58.9539 0 76 17.0469 76 38C76 58.9531 58.9539 76 38 76Z"
              fill="#5BC88D"
            />
            <path
              d="M31.3914 55.8906L13.5015 37.9999L18.174 33.3265L31.3914 46.5439L57.8262 20.1091L62.4987 24.7825L31.3914 55.8906Z"
              fill="white"
            />
          </svg>
          <div class="cxr-wrapper__success-content">
            <div class="cxr-wrapper__success-title">{{ block.payload.success.title }}</div>
            <div class="cxr-wrapper__success-desc">{{ block.payload.success.desc }}</div>
          </div>
        </div>
      </div>
      <div v-else class="cxr-wrapper__main">
        <form @submit.prevent="formSubmit" ref="cxrForm" enctype="multipart/form-data" data-testId="dg-cd-cr-form">
          <div class="cxr-wrapper__reason">
            <DgSelect
              @input="requestType"
              :placeholder="block.payload.main_info.select_placeholder"
              :options="customerRequestOptions"
            ></DgSelect>
          </div>
          <div class="cxr-wrapper__text">
            <div class="cxr-wrapper__desc">
              <textarea
                v-model="request_text"
                :placeholder="block.payload.main_info.text_placeholder"
                name="request_text"
                id="request_text"
                cols="30"
                rows="10"
              ></textarea>
              <div v-if="request_text.length">
                <span :class="['cxr-text', { 'cxr-text__active': request_text.length > 40000 }]"
                  >{{ request_text.length }}/40000</span
                >
              </div>
            </div>
            <div class="cxr-wrapper__files">
              <DgDragnDrop
                @filesDropped="droppedFiles"
                @maxFilesError="maxFilesError"
                @invalidFileTypeError="invalidFileType"
                :maxFiles="5"
                :maxSize="5"
                data-testId="dg-cd-cr-file-upload"
              >
                <template v-slot:container>
                  <div class="cxr-wrapper__dnd">
                    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M31 19H1"
                        stroke="#9AA7B0"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 14V1"
                        stroke="#9AA7B0"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 10H26L31 19V29H1V19L6 10H10"
                        stroke="#9AA7B0"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11 6L16 1L21 6"
                        stroke="#9AA7B0"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M26 24H21"
                        stroke="#9AA7B0"
                        stroke-width="2"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div>
                      <div class="cxr-wrapper__dnd-title">{{ block.payload.main_info.drag_placeholder.title }}</div>
                      <div class="cxr-wrapper__dnd-desc">{{ block.payload.main_info.drag_placeholder.desc }}</div>
                    </div>
                  </div>
                </template>
              </DgDragnDrop>
            </div>
          </div>
          <div class="cxr-wrapper__btn">
            <div class="cxr-wrapper__error-msg" v-if="error">{{ errorText }}</div>
            <DgButton :loading="loadingBtn" nativeType="submit">{{ block.payload.btn_text }}</DgButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Basic, Form } from "@/dgui-customer-components/";
export default {
  name: "CustomerRequest",
  components: {
    DgSelect: Form.DgSelect,
    DgDragnDrop: Form.DgDragnDrop,
    DgButton: Basic.DgButton,
  },
  props: {
    block: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["companyData"]),
    customerRequestOptions() {
      return this.block.payload.subjects.map(subject => {
        return {
          label: subject.title,
          code: subject.request_key,
        };
      });
    },
  },
  data() {
    return {
      loadingBtn: false,
      files: [],
      selectedRequest: null,
      error: false,
      request_text: "",
      errorText: "",
      success: false,
      postError: false,
    };
  },
  methods: {
    invalidFileType(e) {
      if (e) {
        this.error = true;
        this.errorText = this.block.payload.validations.file_type;
      } else {
        this.error = false;
        this.errorText = "";
      }
    },
    maxFilesError(e) {
      if (e) {
        this.error = true;
        this.errorText = this.block.payload.validations.file_count;
      } else {
        this.error = false;
        this.errorText = "";
      }
    },
    requestType(e) {
      this.selectedRequest = e;
    },
    droppedFiles(e) {
      this.files = e;
    },
    clearForm() {
      this.error = false;
      this.success = true;
      this.selectedRequest = null;
      this.request_text = "";
      this.files = [];
      this.loadingBtn = false;
    },
    formSubmit() {
      this.error = true;
      if (!this.selectedRequest) {
        this.errorText = this.block.payload.validations.subject_empty;
        return;
      }

      if (this.request_text === "" || this.request_text.length < 40) {
        this.errorText = this.block.payload.validations.min_char_length;
        return;
      }

      if (this.request_text.length > 40000) {
        this.errorText = this.block.payload.validations.max_char_length;
        return;
      }

      if (this.files.length > 5) {
        this.errorText = this.block.payload.validations.file_count;
        return;
      }

      this.error = false;
      this.loadingBtn = true;
      this.errorText = "";

      let formData = new FormData(this.$refs.cxrForm);

      formData.append("request_title", this.selectedRequest.label);
      formData.append("request_key", this.selectedRequest.code);

      formData.delete("file-input");

      this.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      this.$http({
        url: `/post-form?url=/api1/v2/customer_requests?customer_token=${this.companyData.token}`,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      })
        .then(response => {
          this.clearForm();
          setTimeout(() => {
            this.success = false;
          }, 5000);
        })
        .catch(error => {
          // when request fails
          this.showPostError();
        });
    },
    showPostError() {
      this.postError = true;
      this.loadingBtn = false;
      setTimeout(() => {
        this.postError = false;
      }, 5000);
    },
  },
};
</script>
<style lang="scss" scoped>
.cxr-wrapper {
  margin: auto;
  background: $white;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background: $red;
  }

  .cxr-wrapper__container {
    position: relative;
    min-height: 313px;
    background: $white;

    .cxr-wrapper__sideinfo {
      position: relative;
      background: $red;
      width: 320px;
      color: $white;
      display: table-cell;
      vertical-align: bottom;
      text-align: right;
      padding: 50px 50px 50px 0;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 60px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent $white transparent transparent;
      }

      .cxr-wrapper__dpo {
        position: absolute;
        right: 50px;
        top: 25px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .cxr-wrapper__sideinfo-title {
        font-size: 24px;
        font-weight: bold;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }

      .cxr-wrapper__sideinfo-desc {
        font-size: 12px;
        line-height: 22px;
        float: right;
      }
    }

    .cxr-wrapper__main {
      .cxr-wrapper__success,
      .cxr-wrapper__error {
        &-container {
          display: flex;
          min-height: 313px;
          justify-content: center;
          align-items: center;
        }
        &-content {
          margin-left: 20px;
          flex: 1;
        }
        &-title {
          font-size: 26px;
          line-height: 46px;
          font-weight: 600;
        }
        &-desc {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .cxr-wrapper__reason {
        padding: 22px 40px 0px 40px;
      }

      .cxr-wrapper__text {
        display: flex;

        .cxr-wrapper__files {
          width: 420px;
          padding-right: 40px;
        }

        .cxr-wrapper__desc {
          padding: 0 20px 0 40px;
          flex-grow: 1;
          position: relative;
          textarea {
            width: 100%;
            height: 150px;
            border: 1px solid $light;
            border-radius: 4px;
            resize: none;
            padding: 20px;
          }
        }
      }

      .cxr-wrapper__btn {
        text-align: right;
        padding-right: 40px;
        padding-top: 16px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .cxr-wrapper__error-msg {
          color: $red;
          font-size: 12px;
          margin-right: 20px;
        }
      }
    }

    .cxr-wrapper__dnd {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;
      font-size: 12px;
      color: $gray;

      svg {
        width: 32px;
        height: 32px;
        margin-right: 18px;
      }

      .cxr-wrapper__dnd-title {
        color: $dark;
        font-size: 12px;
      }

      .cxr-wrapper__dnd-desc {
        line-height: 20px;
      }
    }
  }
}

.cxr-text {
  position: absolute;
  right: 20px;
  bottom: -20px;
  font-size: 12px;

  &.cxr-text__active {
    color: rgb(198, 48, 64);
  }
}
</style>
